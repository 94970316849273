.chart_table {
  .table_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
    
  .table_data_columns {
    width: 100%;

    .table_col {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 4px;

      &:not(:first-child) {
        border-top: 1px solid $mid-grey;

        .table_header & {
          border-top: 0;
        }
      }
    }
  }
    
  .table_row_label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 110px;
    flex: 0 0 110px;

    &_icon {
      width: 48px;
      height: 48px;
      flex: 0 0 48px;
      border: 1px solid $off-black;
      border-radius: 200px;
      
      svg {
        width: 100%;
        height: 100%;
      }
    }
    
    &_text {
      margin-top: 5px;
      margin-right: 16px;
    }
  }
    
  .table_data {
    width: calc(100% - 110px);
  }
  
  .table_row_label {
    padding: 4px 0 4px 0;
  }
  
  .table_header .table_row_label,
  .table_data_label {
    padding: 7px 16px 8px 0;
  }
  
  .table_row {
    border-top: 1px solid $dark-grey;
    font-weight: 500;

    &.table_header {
      border-top: 0;
    }
  }
  
  .table_header_desktop {
    display: none;
  }
    
  .data, .table_data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 4px 0;
    gap: 4px;
  }

  .table_data {
    .table_data {
      padding: 0 0 4px 0; // avoid double padding
    }

    .table_col:last-child {
      .table_data {
        padding: 0; // avoid double padding
      }
    }
  }
  
  .data-label {
    font-weight: $font-weight--normal;
  }

  .data-label-value {
    font-weight: $font-weight--heavy;
  }
  
  .data-bar {
    height: 24px;
    background-color: $light-grey;
  }
  
  .data {
    height: 100%;

    &.red-1 {
      background-color: $red;
    }
    &.red-2 {
      background-image: url("/dist/images/patterns/figure-pattern_diagonal65_red.svg");
    }
    &.red-3 {
      background-image: url("/dist/images/patterns/figure-pattern_diagonal35_red.svg");
    }
    &.blue-1 {
      background-color: $blue;
    }
    &.blue-2 {
      background-image: url("/dist/images/patterns/figure-pattern_diagonal65_blue.svg");
    }
    &.blue-3 {
      background-image: url("/dist/images/patterns/figure-pattern_diagonal35_blue.svg");
    }
  }

  .table_col {
    .data-label-mobile {
      width: 30px;
      font-weight: $font-weight--medium;
    }

    .table_data {
      width: calc(100% - 30px);
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .chart_table {
    .table_data_columns {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      gap: 16px;

      .table_col {
        width: 33.333%;

        &:not(:first-child) {
          border-top: 0;
        }
      }
    }

    .table_row_label {
      flex-direction: row;
      width: 220px;
      flex: 0 0 220px;

      &_icon {
        margin-right: 8px;
      }

      &_text {
        margin-top: 8px;
      }
    }

    .table_data {
      width: calc(100% - 220px);
    }

    .table_data {
      .table_data {
        padding: 0; // avoid double padding
      }
    }

    .data-bar {
      height: 30px;
    }

    .table_header_desktop {
      display: flex;

      .table_col {
        padding: 0 16px 8px 0;
      }
    }

    .data-label-mobile {
      display: none;
    }

    .table_col {
      .table_data {
        width: 100%;
      }
    }
  }
}
