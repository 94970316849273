table {
    border-collapse: collapse;

    tr {
      font-size: 1.4rem;
      line-height: 1.6rem;

      th {
        font-weight: 500;
        border-bottom: 1px solid $dark-grey;
      }

      td {
        border-bottom: 1px solid $mid-grey;
      }
    }

    td, th {
      text-align: right;
      padding: .7rem 1.6rem .8rem 0;

      &.series {
        text-align: left;
      }
    }
}