$body_fonts_dir: '/dist/fonts/Inter/';
$heading_fonts_dir: '/dist/fonts/TWK-Everett/';

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-Regular.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-Regular.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 400;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-Italic.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-Italic.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 400;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-Medium.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-Medium.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 500;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-MediumItalic.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-MediumItalic.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 500;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-SemiBold.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-SemiBold.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 600;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-SemiBoldItalic.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-SemiBoldItalic.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 600;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-Bold.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-Bold.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 700;
}

@font-face {
   font-family: 'Inter';
   src: url($body_fonts_dir + 'Inter-BoldItalic.woff2') format("woff2"),
        url($body_fonts_dir + 'Inter-BoldItalic.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 700;
}

@font-face {
   font-family: 'TWK Everett';
   src: url($heading_fonts_dir + 'TWKEverett-Regular-web.woff2') format("woff2"),
        url($heading_fonts_dir + 'TWKEverett-Regular-web.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 400;
}

@font-face {
   font-family: 'TWK Everett';
   src: url($heading_fonts_dir + 'TWKEverett-RegularItalic-web.woff2') format("woff2"),
        url($heading_fonts_dir + 'TWKEverett-RegularItalic-web.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 400;
}

@font-face {
   font-family: 'TWK Everett';
   src: url($heading_fonts_dir + 'TWKEverett-Medium-web.woff2') format("woff2"),
        url($heading_fonts_dir + 'TWKEverett-Medium-web.woff') format("woff");
   font-display: auto;
   font-style: normal;
   font-weight: 500;
}

@font-face {
   font-family: 'TWK Everett';
   src: url($heading_fonts_dir + 'TWKEverett-MediumItalic-web.woff2') format("woff2"),
        url($heading_fonts_dir + 'TWKEverett-MediumItalic-web.woff') format("woff");
   font-display: auto;
   font-style: italic;
   font-weight: 500;
}