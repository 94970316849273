.footnotes {
  @include margin-spacer(16, top);
  @include margin-spacer(16, bottom);
}

.footnotes__heading {
  border-top: 2px solid $off-black;
  @include padding-spacer(3, top);
  @include margin-spacer(12, bottom);
}

.footnotes__back-link {
  position: relative;
  display: inline-block;
  padding-left: 18px;
  margin-left: 2px;

  &__icon {
    position: absolute;
    display: block;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.footnote {
  top: -0.5em;
  font-size: 62.5%;
  font-weight: $font-weight--heavy;

  & > a {
    &:before {
      content: "[";
    }

    &:after {
      content: "]";
    }
  }
}