.content-index {
  @include margin-spacer(16, top);
  @include margin-spacer(16, bottom);

  li {
    margin-bottom: 4px;
    padding-left: 24px;

    &:before {
      top: 12px;
      left: 1px;
      width: 10px;
      height: 0;
      border-top: 2px solid;
      border-radius: 0;
    }

    &.content-index__current {
      font-weight: $font-weight--heavy;

      &:before {
        top: 8px;
        left: 0;
        width: 12px;
        height: 12px;
        border-top: none;
        border-radius: 100%;
      }
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .content-index {
    li {
      padding-left: 28px;
  
      &:before {
        top: 14px;
        width: 12px;
      }

      &.content-index__current {
        &:before {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
}