html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: $base__font-family;
  color: $base__font-colour;
  font-size: $base__font-size--mobile;
  line-height: $base__line-height--mobile;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: $base__font-feature;
}

b, strong {
  font-weight: $font-weight--heavy;
}

// reset margins
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0.5em;
}

h1,
.h1,
.h-xxl,
.page-heading {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $h1__colour;
  font-weight: $h1__font-weight;
  font-size: $h1__font-size--mobile;
  line-height: $h1__line-height--mobile;
}

.page-heading {
  @include margin-spacer(10, bottom);
}

.display-heading {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $h1-disp__colour;
  font-weight: $h1-disp__font-weight;
  font-size: $h1-disp__font-size--mobile;
  line-height: $h1-disp__line-height--mobile;
  @include margin-spacer(6, bottom);
}

.h-xl,
.section-heading {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $h2-lg__colour;
  font-weight: $h2-lg__font-weight;
  font-size: $h2-lg__font-size--mobile;
  line-height: $h2-lg__line-height--mobile;
}

h2,
.h2, 
.h-lg, 
.cross-heading {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $h2__colour;
  font-weight: $h2__font-weight;
  font-size: $h2__font-size--mobile;
  line-height: $h2__line-height--mobile;
}

.cross-heading {
  @include margin-spacer(12, bottom);

  &__number {
    font-weight: $h2-num__font-weight;
    font-size: $h2-num__font-size--mobile;
    line-height: $h2-num__line-height--mobile;
  }
}

h3,
.h3,
.h-md,
.p-heading {
  font-family: $base__font-family;
  color: $h3__colour;
  font-weight: $h3__font-weight;
  font-size: $h3__font-size--mobile;
  line-height: $h3__line-height--mobile;
}

.h-md-disp {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $h3-disp__colour;
  font-weight: $h3-disp__font-weight;
  font-size: $h3-disp__font-size--mobile;
  line-height: $h3-disp__line-height--mobile;
}

.p-heading {
  @include margin-spacer(1, bottom);
}

.h-sm {
  font-family: $heading__font-family;
  color: $h3-sm__colour;
  font-weight: $h3-sm__font-weight;
  font-size: $h3-sm__font-size--mobile;
  line-height: $h3-sm__line-height--mobile;
}

.title-caption {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $lg__colour;
  font-weight: $font-weight--normal;
  font-size: $lg__font-size--mobile;
  line-height: $lg__line-height--mobile;
  @include margin-spacer(4, bottom);
}

.h-xxxl,
.stat__stat {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $stat__colour;
  font-weight: $stat__font-weight;
  font-size: $stat__font-size--mobile;
  line-height: $stat__line-height--mobile;
  letter-spacing: -0.02em;
}

.figure-heading {
  font-family: $base__font-family;
  color: $h-fig__colour;
  font-weight: $h-fig__font-weight;
  font-size: $h-fig__font-size--mobile;
  line-height: $h-fig__line-height--mobile;
}

.standfirst,
.pull-quote {
  font-family: $heading__font-family;
  font-feature-settings: $heading__font-feature;
  color: $stf__colour;
  font-size: $stf__font-size--mobile;
  line-height: $stf__line-height--mobile;
}

.standfirst {
  @include margin-spacer(12, bottom);
}

p {
  @include margin-spacer(8, bottom);
}

.body-sm,
.caption {
  font-family: $base__font-family;
  color: $cap__colour;
  font-weight: $cap__font-weight;
  font-size: $cap__font-size--mobile;
  line-height: $cap__line-height--mobile;
}

.body-xxs {
  font-size: $xxs__font-size--mobile;
  line-height: $xxs__line-height--mobile;
}

.caption {
  @include margin-spacer(3, bottom);
}

.indent-block {
  @include padding-spacer(4, top);
  @include padding-spacer(4, bottom);
  @include padding-spacer(9, left);
  @include margin-spacer(8, top);
  @include margin-spacer(8, bottom);
  position: relative;
  font-weight: $font-weight--medium;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 2px;
    width: 8px;
    height: 100%;
    @include diagonal-line-pattern($off-black, $off-white);
  }
}

.tag {
  display: inline-block;
  padding: 4px 10px;
  background-color: $off-black;
  color: $off-white;
  font-family: $base__font-family;
  font-weight: $font-weight--medium;
  font-size: $cap__font-size--mobile;
  line-height: $cap__line-height--mobile;

  &--lg {
    padding: 4px 24px;
  }
}

a {
  color: $link__colour;
  text-decoration: $link__text-decoration;
  text-decoration-thickness: $link__text-decoration-thickness;
  text-underline-offset: $link__text-underline-offset;
  transition: all $transition;

  .hover-transtion-right {
    transition: all $transition;
  }

  &:hover {
    color: $link-hover__colour;
    text-decoration-thickness: $link-hover__text-decoration-thickness;

    .hover-transtion-right {
      transform: translateX(6px);
    }
  }
}

.page-heading-link__icon {
  width: 12px;
  height: 17px;
  margin-left: 6px;
}

ul,
ol {
  @include list-reset();
  @include margin-spacer(8, bottom);

  li {
    position: relative;
    padding-left: 24px;
    @include margin-spacer(3, bottom);

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
    }
  }
}

ul > li:before {
  display: block;
  width: 8px;
  height: 8px;
  top: 10px;
  background-color: $off-black;
  border-radius: 100%;
}

ol {
  counter-reset: dots;

  > li:before {
    left: -1px;
    counter-increment: dots;
    content: counter(dots, decimal);
    font-weight: $font-weight--bold;
  }
}

.p-heading + ul, 
.p-heading + ol {
  @include margin-spacer(8, top);
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  body {
    font-size: $base__font-size--desktop;
    line-height: $base__line-height--desktop;
  }

  h1,
  .h1,
  .h-xxl,
  .page-heading {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
    letter-spacing: -0.01em;
  }

  .display-heading {
    font-size: $h1-disp__font-size--desktop;
    line-height: $h1-disp__line-height--desktop;
    letter-spacing: -0.01em;
  }

  .h-xl,
  .section-heading {
    font-size: $h2-lg__font-size--desktop;
    line-height: $h2-lg__line-height--desktop;
  }

  h2,
  .h2, 
  .h-lg, 
  .cross-heading {
    font-size: $h2__font-size--desktop;
    line-height: $h2__line-height--desktop;
  }

  .cross-heading__number {
    font-size: $h2-num__font-size--desktop;
    line-height: $h2-num__line-height--desktop;
  }

  h3,
  .h3,
  .h-md,
  .p-heading {
    font-size: $h3__font-size--desktop;
    line-height: $h3__line-height--desktop;
  }

  .h-md-disp {
    font-size: $h3-disp__font-size--desktop;
    line-height: $h3-disp__line-height--desktop;
  }

  .h-sm {
    font-size: $h3-sm__font-size--desktop;
    line-height: $h3-sm__line-height--desktop;
  }

  .title-caption {
    font-size: $lg__font-size--desktop;
    line-height: $lg__line-height--desktop;
  }

  .figure-heading {
    font-size: $h-fig__font-size--desktop;
    line-height: $h-fig__line-height--desktop;
  }

  .h-xxxl,
  .stat__stat {
    font-size: $stat__font-size--desktop;
    line-height: $stat__line-height--desktop;
    letter-spacing: -0.03em;
  }

  .stat__stat {
    font-weight: $font-weight--normal;
  }

  .standfirst,
  .pull-quote {
    font-size: $stf__font-size--desktop;
    line-height: $stf__line-height--desktop;
  }

  .body-sm,
  .caption {
    font-size: $cap__font-size--desktop;
    line-height: $cap__line-height--desktop;
  }

  .tag {
    font-size: $cap__font-size--desktop;
    line-height: $cap__line-height--desktop;

    &--lg {
      padding: 6px 32px;
    }
  }

  .page-heading-link__icon {
    width: 24px;
    height: 34px;
    margin-left: 12px;
  }

  ul,
  ol {
    li {
      padding-left: 30px;
    }
  }

  ul > li:before {
    width: 10px;
    height: 10px;
    top: 11px;
  }

  .h-xxl,
  .page-heading {
    & > a {
      text-decoration-thickness: $link__text-decoration-thickness--lg;
      text-underline-offset: $link__text-underline-offset--lg;

      &:hover {
        text-decoration-thickness: $link-hover__text-decoration-thickness--lg;
      }
    }
  }
}
