.matrix--heading--top-border {
  height: 8px;
  @include diagonal-line-pattern($off-black, $off-white);
  @include margin-spacer(16, top);
  @include margin-spacer(3, bottom);
}

.cross-heading--numbered {
  display: flex;
}

.cross-heading__number {
  margin-top: 6px;
  margin-right: 16px;
}

.matrix {
  &:first-child {
    .container,
    .container--lg,
    .container--md,
    .container--sm {
      &:first-child > .matrix--heading--top-border:first-child {
        @include margin-spacer(16, top);
      }
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .cross-heading__number {
    margin-right: 32px;
  }
}