.matrix--cta-banner {
  position: relative;
  @include margin-spacer(16, top);
  @include margin-spacer(16, bottom);

  &:first-child {
    margin-top: map-get($spacers--mobile, 16) * -1;
  }

  &:last-child {
    margin-bottom: map-get($spacers--mobile, 16) * -1;
  }
}

.cta-banner {
  position: relative;
  @include padding-spacer(16, top);
  @include padding-spacer(16, bottom);

  & > .container {
    position: relative;
  }
}

.cta-banner--graphic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include diagonal-line-pattern--sm($light-grey, $off-white);
}

.cta-banner__image {
  position: relative;
  padding-top: 50%;
  margin-top: map-get($spacers--mobile, 16) * -1;
  z-index: 1;
}

.cta-banner__image-asset {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cta-banner__content {
  @include padding-spacer(9, top);
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .matrix--cta-banner {
    &:first-child {
      margin-top: map-get($spacers--desktop, 16) * -1;
    }
  
    &:last-child {
      margin-bottom: map-get($spacers--desktop, 16) * -1;
    }
  }

  .cta-banner__image {
    margin-top: map-get($spacers--desktop, 16) * -1;
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .cta-banner__content {
    padding-top: 0;
    width: 50%;
  }

  .cta-banner__text {
    padding-right: 96px;
  }

  .cta-banner--graphic {
    left: -999px;
    right: calc(50% + 182px);
    transform: skewX(-45deg);
    transform-origin: right bottom;
    @include vertical-line-pattern($light-grey, $off-white);
  }

  .cta-banner__image {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    left: calc(50% - 182px);
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 0;
    z-index: auto;
  }
}