.matrixes {
  @include padding-spacer(16, bottom);
}

.matrix {
  &:first-child {
    .container,
    .container--lg,
    .container--md,
    .container--sm {
      &:first-child > *:first-child {
        margin-top: 0;
      }
    }
  }

  &:last-child {
    .container,
    .container--lg,
    .container--md,
    .container--sm {
      &:last-child > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}