.content-image {
  @include margin-spacer(10, top);
  @include margin-spacer(14, bottom);
  
  &__asset {
    display: block;
  }

  &__accordion {
    @include margin-spacer(8, top);
    @include margin-spacer(8, bottom);
  }

  &__caption {
    @include margin-spacer(5, top);
  }
}