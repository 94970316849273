/* Animation for all types of bar charts */
.data {
  transition: width 1s ease; 
}
.data-label {
  transition: opacity .5s ease;
}
// Target the class set by AOS ("aos-init") in case the JavaScript doesn't work.
[data-aos="bar-chart-animation"].aos-init:not(.aos-animate) {
  .data {
    width: 0 !important;
  }

  .data-bar .data-label {
    opacity: 0;
  }
}

/* Bar colours for all types of bar charts */
.data {
  background-repeat: repeat;

  &.dark-green {
    background-color: $dark-green;
    color: $white;
  }

  &.green {
    background-color: $green;
    color: $black;
  }

  &.grey {
    background-color: $dark-grey;
  }

  &.pattern {
    background-image: url("/dist/images/patterns/figure-pattern_diagonal35_grey.svg");

    .data-label {display: none;}
  }

  &.pink {
    background-color: $pink;
    color: $black;
  }

  &.dark-pink {
    background-color: $dark-pink;
    color: $white;
  }

  &.red {
    background-color: $red;
    color: $black;
  }
  
  &.blue {
    background-color: $blue;
    color: $black;
  }

  &.transparent {
    background-color: transparent;
  }
}

.data-bar {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &--reverse {
    flex-direction: row-reverse;
  }

  .data-label {
    padding: 0 4px;
    font-weight: $font-weight--medium;
  }
}

$left-margin--mobile: 144px;
$left-margin--desktop: 270px;

/* Style for all types of bar charts */
.bar-chart {
  position: relative;
  width: 100%;

  .data-wrapper {
    flex: 0 0 calc(100% - #{$left-margin--mobile});
    padding: 4px 0;
  }

  .data-bar {
    width: 100%;

    .data {
      position: relative; // for pop-up absolute position
      float: left;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.bar-chart__section {
  position: relative;
}

.bar-chart__rows {
  padding-bottom: 3rem; // x-axes space
  position: relative;
}

.bar-chart__row {
  position: relative;
  border-top: 1px solid $mid-grey;
  height: 39px;
  display: flex;
}

.bar-chart__row__label {
  color: $off-black;
  float: left;
  text-align: right;
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex: 0 0 $left-margin--mobile;
}

.bar-chart__x-axes {
  position: relative;
  border-top: 1px solid;
  color: $dark-grey;
  margin-left: $left-margin--mobile;
}

.bar-chart__x-axis {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(-50%); // move up half of font size

  span {
    color: $off-black;
  }
}

.bar-chart__x-axis-line {
  width: 0;
  height: 6px;
  border-left: 1px solid;
}

.bar-chart__bg-lines {
  position: absolute;
  top: 0; 
  left: $left-margin--mobile;
  right: 0; 
  bottom: 3rem;
}

.bar-chart__bg-line {
  position: absolute;
  color: $mid-grey;

  &--dark-grey {
    color: $dark-grey;
  }

  &--x {
    top: 0;
    height: 100%;
    border-left: 1px solid;
    transform: translateX(-0.5px); // for border left width
  }
}

// pop-up
.bar-chart__rows__pop-up {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .bar-chart__row {
    border-top: 1px solid transparent;
  }

  .chart--pop-up {
    &--left {
      right: 50%;
    }

    &--up {
      left: 50%;
      transform: translate(-50%, -50%); // horizontal center
    }
  }
}

.bar-chart {
  .data:hover {
    .chart--pop-up {
      opacity: 1;
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .bar-chart {
    .data-wrapper {
      flex: 0 0 calc(100% - #{$left-margin--desktop});
    }
  }
  .bar-chart__row__label {
    flex: 0 0 $left-margin--desktop;
  }

  .bar-chart__x-axes {
    margin-left: $left-margin--desktop;
  }

  .bar-chart__bg-lines {
    left: $left-margin--desktop;
  }
}