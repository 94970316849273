.line-chart__canvas {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.line-chart__graphs {
  position: relative;
  height: 400px;
  width: calc(100% - 35px);
  margin-left: 35px;
}

.line-chart__cat {
  color: $dark-grey;

  &--green {
    color: $green;
  }

  &--dark-green {
    color: $dark-green;
  }

  &--pink {
    color: $pink;
  }

  &--dark-pink {
    color: $dark-pink;
  }
}

.line-chart__line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__block {
    position: absolute;

    &--down {
      transform: scaleY(-1);
      transform-origin: bottom left;
    }
  }

  &--diagonal-line {
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
  }

  &--point {
    position: absolute;
    bottom: 0; 
    left: 0;
    transform: translate(-3px, 3px); // position center
  }
}

.line-chart__y-axes {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 100%;
  color: $dark-grey; // for border colour
  border-right: 1px solid;
  transform: translateX(0.5px); // for y-axes right line
}

.line-chart__y-axis {
  position: absolute;
  right: 0;
  display: flex;
  color: $off-black;
  align-items: center;
  transform: translateY(-50%); // move up half of font size
}

.line-chart__y-axis-line {
  width: 10px;
  height: 0;
  color: $dark-grey; // for border colour
  border-top: 1px solid;
}

.line-chart__x-axes {
  position: absolute;
  top: 100%;
  width: 100%;
  color: $dark-grey; // for border colour
  border-top: 1px solid;
  width: calc(100% - 35px);
  margin-left: 35px;
  transform: translateY(-0.5px); // for x-axes line height
}

.line-chart__x-axis {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  color: $off-black;
  align-items: center;
  transform: translateX(-50%); // move up half of font size
}

.line-chart__x-axis-line {
  color: $dark-grey; // for border colour
  width: 0;
  height: 10px;
  border-left: 1px solid;
}

.line-chart__bg-lines {
  position: absolute;
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%;
}

.line-chart__bg-line {
  position: absolute;
  color: $mid-grey;

  &--y {
    left: 0;
    width: 100%;
    border-top: 1px solid;
    transform: translateY(-0.5px); // for border top width
  }

  &--x {
    top: 0;
    height: 100%;
    border-left: 1px solid;
    transform: translateX(-0.5px); // for border left width
  }
}

// pop-up info
.line-chart__line--point--hover {
  transform: translate(-8px, 8px); // center align
  cursor: pointer;

  &:hover ~ .chart--pop-up {
    // display: block;
    opacity: 1;
  }
}

// show/hide 
.line-chart {
  padding-bottom: 3rem; // x-axes space

  // max number of categories is 8
  @for $i from 1 through 10 {
    input[type="checkbox"].data-#{$i}:checked ~ .line-chart__canvas .line-chart__cat--#{$i} {
      height: 0;
      opacity: 0;
      transition: height 1s ease, opacity .3s ease 1s;
    }
  }
}

// animation
// Target the class set by AOS ("aos-init") in case the JavaScript doesn't work.
[data-aos="line-chart-animation"].aos-init:not(.aos-animate) {
  .line-chart__cats {
    height: 0 !important;
    opacity: 0;
    transition: height 1s ease, opacity .3s ease 1s;
  }
}

.line-chart__cat, // legend show/hide
.line-chart__cats { // aos animation 
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0; // put bottom as starting point for css animation
  height: 100%;
  opacity: 1;
  transition: opacity .3s ease, height 1s ease .3s;
}

.line-chart__cats__pop-up {
  .chart--pop-up {
    &--right {
      transform: translateY(-50%); // vertical-center
    }
    
    &--left {
      transform: translate(-100%, -50%); // horizontal left to prevent overflow/vertical center
    }
    
    &--down {
      transform: translateX(-50%); // horizontal center
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .line-chart__graphs {
    height: 480px;
  }
}