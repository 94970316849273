@use 'sass:math';

@import "reset/normalize";
@import "reset/reset";

@import "utility/helpers";
@import "utility/variables";
@import "utility/accessibility";
@import "utility/print";

@import "base/font-face";
@import "base/typography";
@import "base/buttons";
@import "base/forms";
@import "base/tables";

@import "layout/bootstrap-grid";
@import "layout/container";
@import "layout/sections";

@import "components/site-header";
@import "components/site-main";
@import "components/site-footer";
@import "components/site-hero";
@import "components/accordion";
@import "components/logo";
@import "components/footnotes";
@import "components/standfirst";
@import "components/article-author";
@import "components/listing";
@import "components/filtering";
@import "components/pagination";
@import "components/content-index";
@import "components/next-entry";
@import "components/breadcrumb";

@import "matrix/matrix";
@import "matrix/text";
@import "matrix/heading";
@import "matrix/image";
@import "matrix/chart-all";
@import "matrix/chart-bar";
@import "matrix/chart-stacked-bar";
@import "matrix/chart-table";
@import "matrix/chart-line";
@import "matrix/stat";
@import "matrix/stats";
@import "matrix/pull-quote";
@import "matrix/related-content";
@import "matrix/cta-banner";

admin-bar {
    --admin-bar-font-size: 1.4rem;
    z-index: var(--admin-bar-z-index, 100000);
}