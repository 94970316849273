.matrix--chart {
  @include margin-spacer(16, top);
  @include margin-spacer(13, bottom);

  /* Chart legends */
  input[type="checkbox"] {
    display: none;
  }

  .legend-toggle {
    width: 1.6rem;
    height: 1.6rem;
    background-size: 1.6rem;
    background-repeat: no-repeat;
    background-image: url("/dist/images/icons/eye-open.svg");
    flex-shrink: 0; // Stop the nested icon from shrinking
  }

  input[type="checkbox"].data-1:checked ~ .chart_legend .legend-1,
  input[type="checkbox"].data-2:checked ~ .chart_legend .legend-2,
  input[type="checkbox"].data-3:checked ~ .chart_legend .legend-3,
  input[type="checkbox"].data-4:checked ~ .chart_legend .legend-4 {
    background-color: $light-grey;

    .legend-toggle {
      background-image: url("/dist/images/icons/eye-closed.svg");
    }

    &:hover {
      background-color: $off-black;
      .legend-toggle {
        background-image: url("/dist/images/icons/eye-closed_white.svg");
      }
    }
  }

  button.copy-link {
    cursor: pointer;
    @include margin-spacer(8, top);
  }
  
  .button.download-link {
    @include margin-spacer(3, bottom);
  } 

  .table_div {
    width: 100%;
    overflow: auto;
  }
}

.chart_heading {
  font-weight: $font-weight--normal;
  padding: 4px 0 16px;
  border-top: 2px solid $off-black;

  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 0;
    padding-top: .4rem;

    &.question {
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding-top: .8rem;

      svg {
        height: 1.5rem;
        width: 1.5rem;
        flex: 0 0 1.5rem;
        margin-right: .25rem;
      }
    }
  }
}

.chart_div {
  font-weight: $font-weight--normal;
  font-size: 1.4rem;
  line-height: 1.6rem;
  position: relative;

  .chart__x-axis-point, 
  .chart__y-axis-point, 
  .x-axis-label {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}

.chart_legend {
  padding-bottom: 1.2rem;
  display: flex;
  flex-wrap: wrap;
  gap: .6rem;

  .legend {
    max-width: 100%;
    padding: .3rem .4rem;
    background-color: $white;
    border: 1px solid $off-black;
    border-radius: 2px;
    display: flex;
    align-items: top;
    gap: .4rem;
    cursor: pointer;
    color: $off-black;

    &:hover {
      background-color: $off-black;
      color: $off-white;
      .legend-toggle {
        background-image: url("/dist/images/icons/eye-open_white.svg");
      }
    }
  }

  .legend-swatch {
    width: 1.4rem;
    height: 1.4rem;
    flex-shrink: 0; // Stop the nested shape from shrinking
    margin-top: .1rem;
  }

  &--reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

.legend-swatch {
  background-color: $dark-grey;

  .dark-green & {
    background-color: $dark-green;
  }
  .green & {
    background-color: $green;
  }
  .pattern & {
    background-image: url("/dist/images/patterns/figure-pattern_diagonal35_grey.svg");
    background-repeat: repeat;
  }
  .pink & {
    background-color: $pink;
  }
  .dark-pink & {
    background-color: $dark-pink;
  }
}

.chart_navigation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 1.2rem;

  .rule {
    height: 1px;
    width: 100%;
    background-color: $off-black;
    order: 1;
    z-index: 0;
  }

  .chart_tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    margin: -1px 0px;
    z-index: 1;

    .tab {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 1.6rem;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
      
      label {
        background-color: $light-grey;
        padding-top: 10px;
        padding-bottom: 9px;
        padding-left: 2rem;
        padding-right: 2rem;
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: $off-black;
        border-radius: 4px 4px 0 0;
        cursor: pointer;
      }
    }
  }
}

.chart-tab,
input[type="radio"].tab {
  display: none;
}

@for $i from 1 through 2 {
  input[type="radio"].tab--#{$i}:checked ~ .chart_navigation .tab--#{$i} {
    label {
      background-color: $off-white;
      padding-bottom: 10px;
    }
  }

  input[type="radio"].tab--#{$i}:checked ~ .chart-tab--#{$i} {
    display: block;
  }
}

// pop-up
.chart--pop-up--pointer {
  position: absolute;
}

.chart--pop-up--info {
  display: inline-block;
  padding: 7px;
  background-color: $white;
  border: 1px solid $off-black;
  border-radius: 2px;
  color: $off-black;
  text-align: left;
  font-weight: $font-weight--normal;

  strong {
    font-weight: $font-weight--bold;
  }
}

.chart--pop-up {
  position: absolute;
  min-width: 220px; // enough space for info block, when bigger causing mobile view error

  @media screen and (min-width: map-get($breakpoints, md)) {
    min-width: 280px; // enough space for info block
  }
  
  &--right {
    .chart--pop-up--info {
      margin-left: 6px;
    }
    
    .chart--pop-up--pointer {
      top: 50%;
      transform: translateY(-50%); // vertical-center
      width: 7px;
      height: 14px;
      background-image: url('data:image/svg+xml,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8L8 1V15L1 8Z" fill="white"/><path d="M8 1L1 8L8 15" stroke="%233F3F3F"/></svg>');
      background-position: left center;
      background-repeat: no-repeat;
    }
  }
  
  &--left {
    text-align: right;

    .chart--pop-up--info {
      margin-right: 6px;
    }
    
    .chart--pop-up--pointer {
      right: 0;
      top: 50%;
      transform: translateY(-50%); // vertical-center
      width: 7px;
      height: 14px;
      background-image: url('data:image/svg+xml,<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 8L1 15L1 0.999999L8 8Z" fill="white"/><path d="M1 15L8 8L1 0.999999" stroke="%233F3F3F"/></svg>');
      background-position: right center;
      background-repeat: no-repeat;
    }
  }

  &--up {
    text-align: center;
    
    .chart--pop-up--info {
      margin-bottom: 6px;
    }

    .chart--pop-up--pointer {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); // horizontal center
      width: 14px;
      height: 7px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='9' viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 7.5L0.500001 0.5L14.5 0.500002L7.5 7.5Z' fill='white'/%3E%3Cpath d='M0.500001 0.5L7.5 7.5L14.5 0.500002' stroke='%233F3F3F'/%3E%3C/svg%3E%0A");
      background-position: center bottom;
      background-repeat: no-repeat;
    }
  }
  
  &--down {
    text-align: center;
    
    .chart--pop-up--info {
      margin-top: 6px;
    }
    
    .chart--pop-up--pointer {
      top: 0;
      left: 50%;
      transform: translateX(-50%); // horizontal center
      width: 14px;
      height: 7px;
      background-image: url('data:image/svg+xml,<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 1.5L14.5 8.5L0.5 8.5L7.5 1.5Z" fill="white"/><path d="M14.5 8.5L7.5 1.5L0.5 8.5" stroke="%233F3F3F"/></svg>');
      background-position: center top;
      background-repeat: no-repeat;
    }
  }
  
  // show/hide of pop-ups
  opacity: 0;
  pointer-events: none; // prevent hover show blinking
  transition: all .2s ease-in;
}