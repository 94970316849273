.pull-quote {
  margin: 0;
  @include margin-spacer(10, top);
  @include margin-spacer(10, bottom);
  display: flex;
  flex-direction: column;
}

.pull-quote__quote {
  margin: 0;
  @include padding-spacer(8, top);
  @include padding-spacer(9, bottom);
}

.pull-quote__top-border,
.pull-quote__bottom-border {
  height: 8px;
  @include diagonal-line-pattern($off-black, $off-white);
}

.pull-quote__bottom-border {
  flex-grow: 1;
}

.pull-quote__top-border {
  flex-grow: 1;
}

.pull-quote__head {
  display: flex;
  align-items: flex-end;
}

.pull-quote__tail {
  display: flex;
  align-items: flex-start;
}

.pull-quote__icon {
  display: block;
  flex-shrink: 0;
  height: 40px;
  width: auto;
  margin-right: 16px;

  &--close {
    margin-left: 16px;
    margin-right: 0;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .pull-quote__icon {
    height: 56px;
  }
}