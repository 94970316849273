.site-footer {
  position: relative;
  width: 100%;
  background-color: $off-black;
  color: $off-white;
  @include padding-spacer(16, top);
  @include padding-spacer(14, bottom);

  ul {
    @include list-reset();
    display: flex;

    li {
      @include list-reset();
      margin-right: 12px;
      @include margin-spacer(3, bottom);
  
      &:before {
        content: "";
        position: static;
        display: inline;
        width: 0;
        height: auto;
        top: auto;
        background-color: none;
        border-radius: 0;
        border-left: 1px solid $dark-grey;
        padding-left: 12px;
      }

      &:first-of-type {
        &:before {
          display: none;
        }
      }
    }

    @media screen and (max-width: map-get($breakpoints, md)) {
      display: block;

      li {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  a {
    color: $off-white;
    border-color: $off-white;
  }

  .main-logo {
    width: 214px;
    height: 80px;
    @include margin-spacer(11, bottom);

    @media screen and (min-width: map-get($breakpoints, sm)) {
      width: 264px;
      height: 100px;
    }
  }

  .footer-contacts {
    @include margin-spacer(3, bottom);
  }
  
  .footer-address {
    @include margin-spacer(4, bottom);
  }

  .footer-social-links {
    display: flex;
    @include margin-spacer(10, bottom);

    .footer-social-link {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 12px;
      border-radius: 20px;

      svg {
        width: 100%;
        height: 100%;
      }

      &:hover {
        color: $link__colour;
        background-color: $white;

        rect {
          stroke: $white;
        }
      }

      &:focus-visible {
        box-shadow: none; // override the link focus style

        rect {
          stroke: $accessibility__highlight;
        }
      }
    }
  }

  .footer-menu {
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    @include padding-spacer(7, top);
    @include padding-spacer(6, bottom);
    @include margin-spacer(6, bottom);
    font-size: $cap__font-size--mobile;
    line-height: $cap__line-height--mobile;
  }

  .footer-credits {
    display: flex;
    justify-content: space-between;
    font-size: $cap__font-size--mobile;
    line-height: $cap__line-height--mobile;

    @media screen and (max-width: map-get($breakpoints, md)) {
      display: block;

      span {
        display: block;
        margin-bottom: 8px;
      }
    }
  }
}

.site-footer--graphic-asset {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 83.33px;
}

//------//
// > xs //
//------//

@media screen and (min-width: map-get($breakpoints, xs)) {
  .site-footer--graphic-asset {
    width: 150px;
    height: 125px;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-footer--graphic-asset {
    width: 240px;
    height: 200px;
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .site-footer--graphic-asset {
    width: 300px;
    height: 250px;
  }
}