.site-header {
  width: 100%;
  position: relative;
  z-index: 5;

  // NOTE: this is resetting some defaults for ULs that we don't want here  
  ul {
    @include list-reset();

    li {
      @include list-reset();
  
      &:before {
        display: none;
      }
    }
  }

  .site-header__inner {
    padding-top: 32px;
    @include padding-spacer(13, bottom);
    background-color: $off-white;
    position: relative;
  }

  .site-header-top {
    .mobile-menu-button {
      display: none;
    }
  }

  .main-nav-wrapper {
    display: flex;
    align-items: center;
    @include margin-spacer(5, top);
  }

  .site-title {
    flex-grow: 1;
    font-weight: $font-weight--medium;
    margin-bottom: 0;
  }

  .main-nav {
    display: flex;
    margin-bottom: -8px;

    .main-nav__top-level-item {
      position: static;

      .main-nav__top-level-item-button {
        position: relative;
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        text-decoration: none;
        margin-left: 4rem;
        margin-right: 3.2 - .8rem;
        border-bottom: 4px solid transparent;
        -webkit-tap-highlight-color: transparent; // 
        
        &:after {
          content: "";
          position: absolute;
          display: block;
          top: .6rem;
          right: -3.2rem;
          width: 3.2rem;
          height: 1.8rem;
          background-image: url("/dist/images/icons/chevron-down_dark-grey.svg");
          background-repeat: no-repeat;
          background-position: center;
          transition: .25s transform;
        }

        &:hover {
          text-decoration: none;
          border-color: $off-black;
        }

        &:focus-visible {
          @include accessible-focus-state();
        }

        &--active {
          border-color: $off-black;
          
          &:after {
            transform: rotate(180deg);
          }
          
          &:hover {
            text-decoration: none;
          }

          &:focus-visible {
            box-shadow: none;
          }
        }
      }

      .main-nav__second-level {
        display: flex;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0;
        @include diagonal-line-pattern--sm($light-grey, $off-white);
        @include padding-spacer(13, top);
        @include padding-spacer(13, left);
        @include padding-spacer(13, right);
        @include padding-spacer(13, bottom);
        opacity: 0;
        z-index: 999;
        pointer-events: none;

        &--visible {
          opacity: 1;
          pointer-events: all;
        }

        .main-nav__second-level-description {
          width: 35.2rem;
          @include margin-spacer(9, right);

          .main-nav__second-level-title {
            font-weight: 500;
            display: inline-block;
            @include margin-spacer(4, bottom);
          }
        }

        .main-nav__second-level-links {
          position: relative;
          @include padding-spacer(10, left);
          @include padding-spacer(2, bottom);
          @include margin-spacer(5, bottom);

          &:before {
            content: "";
            display: block;
            width: .8rem;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            @include diagonal-line-pattern($off-black, $off-white);
          }

          .main-nav__second-level-item {
            @include margin-spacer(5, bottom);
            font-weight: 500;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &--bottom-border {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    @include diagonal-line-pattern($off-black, $off-white);
  }

  &--site-greyout {
    background-color: rgba($black, 0.3);
    pointer-events: none;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // transition: opacity .25s;

    &--active {
      opacity: 1;
      pointer-events: all;
    }

    &--mobile {
      display: none;
    }
  }

  @media screen and (max-width: 1150px) {
    .site-header__inner {
      padding-bottom: 48px;
    }

    .main-nav-wrapper {
      display: block;
    }

    .site-title {
      padding-bottom: 32px;
    }

    .main-nav {
      .main-nav__top-level-item {
        .main-nav__top-level-item-button {
          margin-left: 0;
          margin-right: 4.8rem;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    .site-title {
      padding-bottom: 32px;
    }
  }

  @media screen and (max-width: map-get($breakpoints, md)) {
    .site-header__inner {
      padding-bottom: 0;
    }

    .site-header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .mobile-menu-button {
        @include button-reset;
        display: block;
        width: 48px;
        height: 48px;
        background-image: url("/dist/images/icons/mobile-menu-button.svg");
        cursor: pointer;
  
        &--active {
          background-image: url("/dist/images/icons/mobile-menu-close-button.svg");
        }
      }
    }

    .site-title {
      margin: 0;
      padding-bottom: 48px;
    }

    .main-nav {
      display: none;
      @include diagonal-line-pattern--sm($light-grey, $off-white);
      padding-bottom: 28px;
      padding-left: $container__gutter--lg;
      padding-right: $container__gutter--lg;
      position: absolute;
      left: 0;
      right: 0;

      &--visible {
        display: block;
      }

      .main-nav__top-level-item {
        .main-nav__top-level-item-button {
          display: block;
          margin: 0;
          border-bottom: none;
          border-top: 1px solid $dark-grey;
          padding: 14px 0;
          
          &:after {
            top: calc(50% - .9rem);
            right: 0;
            width: 1.6rem;
          }

          &:hover {
            text-decoration: $link__text-decoration;
            text-decoration-thickness: $link__text-decoration-thickness;
            text-underline-offset: $link__text-underline-offset;
          }
        }

        .main-nav__second-level {
          border-top: 1px solid $dark-grey;
          display: block;
          position: static;
          background-image: none;
          padding: 14px 0;
          display: none;

          &--visible {
            display: block;
            opacity: 1;
            pointer-events: all;
          }

          .main-nav__second-level-description {
            width: auto;
            margin: 0 0 20px;
          }
        }

        &:first-child {
          .main-nav__top-level-item-button {
            border-top: none;
          }
        }
      }
    }

    .site-header--site-greyout--mobile {
      display: block;
    }

    .site-header--site-greyout--desktop {
      display: none;
    }

    @media screen and (max-width: 639px) {
      .site-title {
        padding-bottom: 32px;
      }

      .main-nav {
        padding-left: $container__gutter--md;
        padding-right: $container__gutter--md;
      }
    }

    @media screen and (max-width: 479px) {
      .main-nav {
        padding-left: $container__gutter--sm;
        padding-right: $container__gutter--sm;
      }
    }
  }
}

// NOTE: the following is copied/adapted from styles/layout/_container.scss so as to allow us to 
// match the spacing of the header to the spacing of the page containers, but with some adjustments

.site-title {
  font-family: $heading__font-family;
  white-space: nowrap;
  overflow: hidden;
}

.site-title,
.site-header-top {
  padding-left: $container__gutter--sm;
  padding-right: $container__gutter--sm;
}

.main-nav-wrapper {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .site-header-top,
  .site-title {
    padding-left: $container__gutter--md;
    padding-right: $container__gutter--md;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-header-top,
  .site-title {
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }

  .site-header .site-header__inner {
    padding-top: 48px;
  }
}

@media screen and (min-width: map-get($breakpoints, md)) {
  .site-header-top,
  .main-nav-wrapper {
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }

  .site-title {
    padding-left: 0;
    padding-right: 0;  
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .site-header-top,
  .main-nav-wrapper {
    padding-left: $container__gutter--xl;
    padding-right: $container__gutter--xl;
  }

  .site-header .site-header__inner {
    padding-top: 64px;
  }
}
