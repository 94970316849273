@mixin accessible-focus-state() {
  outline: none;
  background-color: $accessibility__highlight;
  color: $accessibility__text !important;
  box-shadow: $accessibility__box-shadow;
  text-decoration: none;

  &:not(.no-path-fill) {
    path {
      fill: $accessibility__text !important;
    }
  }
}

a, 
button,
.button {
  &:focus-visible {
    @include accessible-focus-state();
  }
  @supports not selector(:focus-visible) {
    &:focus {
      /* Fallback for browsers without :focus-visible support */
      @include accessible-focus-state();
    }
  }
}

.visually-hidden {
  position: absolute;
  left: -9999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

.skip-link:focus-visible {
  @include accessible-focus-state();
  position: fixed;
  top: 1em;
  left: 1em;
  width: auto;
  height: auto;
  z-index: 99;
}
