@mixin list-reset() {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin button-reset() {
  margin: 0;
  padding: 0;
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  text-align: inherit;
  line-height: normal;
  font-weight: inherit;
  background-color: transparent;
  transition: none;

  &:hover {
    background-color: transparent;
  }
}

@function line-height-calc($line-height, $font-size) {
  @return math.div($line-height, $font-size);
}

@function hover-colour($colour) {
  @if $hover__effect == darken {
    @return darken($colour, $hover__effect-amount);
  }

  @if $hover__effect == lighten {
    @return lighten($colour, $hover__effect-amount);
  }

  @return $colour;
}

@mixin last-child-no-bottom-margin {
  > *:last-child {
    margin-bottom: 0;
  }
}

.lc--nbm {
  @include last-child-no-bottom-margin();
}

@mixin first-child-no-top-margin {
  > *:first-child {
    margin-top: 0;
  }
}

.fc--ntm {
  @include first-child-no-top-margin();
}

@mixin margin-spacer($size, $direction: bottom) {
  margin-#{$direction}: map-get($spacers--mobile, $size);

  @media screen and (min-width: map-get($breakpoints, sm)) {
    margin-#{$direction}: map-get($spacers--desktop, $size);
  }
}

@mixin padding-spacer($size, $direction: bottom) {
  padding-#{$direction}: map-get($spacers--mobile, $size);

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding-#{$direction}: map-get($spacers--desktop, $size);
  }
}

@mixin diagonal-line-pattern($colour-1, $colour-2) {
  background-image: linear-gradient(135deg, $colour-1 25%, $colour-2 25%, $colour-2 50%, $colour-1 50%, $colour-1 75%, $colour-2 75%, $colour-2 100%);
  background-size: 5.66px 5.66px;
}

@mixin diagonal-line-pattern--sm($colour-1, $colour-2) {
  background-image: linear-gradient(135deg, $colour-1 25%, $colour-2 25%, $colour-2 50%, $colour-1 50%, $colour-1 75%, $colour-2 75%, $colour-2 100%);
  background-size: 4.66px 4.66px;
}

@mixin vertical-line-pattern($colour-1, $colour-2) {
  background-image: linear-gradient(90deg, $colour-1 25%, $colour-2 25%, $colour-2 50%, $colour-1 50%, $colour-1 75%, $colour-2 75%, $colour-2 100%);
  background-size: 10px 10px;
}

// Will return white or black depending on which is more accessible on a background colour
// See: http://www.davidhalford.com/thoughts/2013/auto-contrasting-text/
@function text-contrast($colour) {
  $colour-brightness: round(red($colour) * 299 + green($colour) * 587 + math.div(blue($colour) * 114, 1000));
  $light-colour: round(red(#ffffff) * 299 + green(#ffffff) * 587 + math.div(blue(#ffffff) * 114, 1000));

  @if abs($colour-brightness) < math.div($light-colour, 2) {
    @return $white;
  } @else {
    @return $black;
  }
}
