.article-author {
  @include margin-spacer(14, top);
}

.article-author__photo {
  position: relative;
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: row-reverse;
  margin-left: 16px;
}

.article-author__photo-pattern {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80px;
  height: 80px;
  @include diagonal-line-pattern($off-black, $off-white);
}

.article-author__photo-frame {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  &.headshot {
    background-color: $light-grey;
  }
}

.article-author__photo-asset {
  width: 100%;
  height: auto;
  border-radius: 50%;
  mix-blend-mode: multiply;
}

.article-author__content {
  padding: 56px 16px 24px;
  @include diagonal-line-pattern--sm($light-grey, $off-white);
  margin-top: -40px;
}

.article-author__details {
  @include margin-spacer(4, bottom);
  max-width: 640px;
}

.article-author__description {
  max-width: 640px;
}

.article-author__name {
  margin-bottom: 0;
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .article-author__photo {
    margin-left: 0;
  }

  .article-author__content {
    padding: 32px 32px 32px 88px;
    margin-top: -80px;
    margin-left: 32px;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .article-author__photo {
    width: 160px;
    height: 160px;
  }

  .article-author__photo-pattern {
    width: 120px;
    height: 120px;
  }

  .article-author__photo-frame {
    width: 120px;
    height: 120px;
  }

  .article-author__content {
    padding: 32px 32px 32px 152px;
    margin-top: -120px;
    margin-left: 40px;
  }
}