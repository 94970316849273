.stats {
  @include margin-spacer(14, top);
  @include margin-spacer(14, bottom);
}

.stats__stat {
  position: relative;
  @include padding-spacer(9, left);
  max-width: 288px;
}

.stats__stat__stat {
  font-family: $heading__font-family;
  font-weight: $h1-disp__font-weight;
  font-size: $h1-disp__font-size--mobile;
  line-height: $h1-disp__line-height--mobile;
  font-weight: $font-weight--normal;
}

.stats__stat__graphic {
  position: absolute;
  top: 0;
  left: 1px;
  width: 8px;
  height: 100%;
  background-image: url("/dist/images/graphics/stat-green-pink.svg");
  background-repeat: no-repeat;
  background-position: center;

  &--2 {
    background-image: url("/dist/images/graphics/stat-blue-red.svg");
  }

  &--3 {
    background-image: url("/dist/images/graphics/stat-pink-blue.svg");
  }
}

.stats__stat__caption {
  padding-bottom: 8px;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .stats__stat {
    max-width: 448px;
  }

  .stats__stat__stat {
    font-size: $h1__font-size--desktop;
    line-height: $h1__line-height--desktop;
  }
}