.stacked-bar-chart {
  .data-label {
    opacity: 0;
  }

  .data-bar {
    .data {
      &:not(:last-child) {
        border-right: 1px solid $off-white;
      }
    }
  }

  .bar-chart__rows__pop-up {
    .data-bar {
      .data {
        &:not(:last-child) {
          border-right: 1px solid transparent;
        }
      }
    }
  }

  //show hide
  // max number of categories is 8
  @for $i from 1 through 10 {
    input[type="checkbox"].data-#{$i}:checked ~ .bar-chart__section .data-#{$i} {
      width: 0 !important;
      border-right-width: 0;

      .data-label {opacity: 0;}
    }
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .stacked-bar-chart {
    .data-label {
      opacity: 1;
    }
  }
}