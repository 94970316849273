.site-hero {
  position: relative;

  &--container {
    position: relative;
    @include padding-spacer(16, top);
    padding-right: 50px;
  }

  &--padding-bottom {
    @include padding-spacer(16, bottom);
  }

  &--spacer {
    padding-top: 20px;
  }
}

.hero-image {
  position: relative;
  @include margin-spacer(16, top);
  @include margin-spacer(16, bottom);

  &__asset {
    position: relative;
    display: block; // no bottom margin
  }

  &__article {
    @include margin-spacer(10, top);
  }
}

.site-hero--diagonal-pattern {
  @include diagonal-line-pattern--sm($light-grey, $off-white);
}

.hero-image--light-grey-bg {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 50%;
  background-color: $off-white;
}

.site-hero--graphic {
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 250px; // prevent svg graphic gets hidden
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.site-hero--graphic-asset {
  position: absolute;
  width: 100px;
  height: 100px;
  right: -50px;
}

//homepgae
.homepage-hero--container {
  position: relative;
  padding-top: 28px;
  padding-right: 75px;
}

.site-hero--graphic--homepage {  
  svg {
    position: absolute;
    width: 150px;
    height: 168px;
    right: -75px;
  }
}

@media screen and (min-width: map-get($breakpoints, xs)) {
  .site-hero--spacer {
    padding-top: 40px;
  }

  .site-hero--container {
    padding-right: 100px;
  }

  .site-hero--graphic-asset {
    right: 0;
  }

  .homepage-hero--container {
    padding-right: 100px;
  }

  .site-hero--graphic--homepage {
    svg {
      width: 200px;
      height: 225px;
      right: -100px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .site-hero--container {
    padding-right: 150px;
  }

  .site-hero--graphic-asset {
    width: 150px;
    height: 150px;
  }

  .homepage-hero--container {
    padding-top: 40px;
    padding-right: 102px;
  }

  .site-hero--graphic--homepage {
    min-height: 450px; // prevent svg graphic gets hidden

    svg {
      width: 300px;
      height: 337.5px;
      right: -150px;
    }
  }
}

@media screen and (min-width: map-get($breakpoints, lg)) {
  .site-hero--container {
    padding-right: 250px;
  }

  .site-hero--graphic {
    overflow: visible;
  }

  .site-hero--graphic-asset {
    width: 250px;
    height: 250px;
  }

  .homepage-hero--container {
    padding-top: 96px;
    padding-right: 336px;
  }

  .site-hero--graphic--homepage {
    svg {
      position: absolute;
      width: 400px;
      height: 450px;
      right: 0;
    }
  }
}