.stat {
  @include margin-spacer(14, top);
  @include margin-spacer(14, bottom);
  display: flex;
  flex-direction: column;
  @include padding-spacer(9, left);
  position: relative;
  max-width: 288px;

  &:before {
    content: "";
    display: block;
    width: .8rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-image: url("/dist/images/graphics/stat-all-colours.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.stat__caption {
  @include margin-spacer(4, bottom);
  width: 100%;
  font-weight: $font-weight--medium;
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .stat {
    max-width: 448px;
  }
}