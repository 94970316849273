.filtering {
  display: flex;
  @include margin-spacer(12, top);
  @include margin-spacer(12, bottom);
}

.filtering__heading {
  flex: 0 0 56px;
  margin-top: 5px;
}

.filtering__filters {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -3px;
  
  li {
    margin: 4px 3px;
    padding-left: 0;

    &:before {
      content: none;
    }
  }
}

.filtering__filter {
  color: $btn__text-colour;
  background-color: $btn__background-colour;
  padding: $btn__padding;
  border: $btn__border;
  border-radius: $btn__border-radius;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: $btn-hover__text-colour;
    background-color: $btn-hover__background-colour;
  }
}

.filtering__filter-close {
  margin-left: 8px;
  margin-right: -2px;
}

input[type=checkbox]:checked + .filtering__filter {
  color: $off-white;
  background-color: $off-black;

  &:hover {
    color: $btn-hover__text-colour;
    background-color: $btn-hover__background-colour;
  }
}

input[type=checkbox]:focus-visible + .filtering__filter {
  color: $accessibility__text;
  background-color: $accessibility__highlight;
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .filtering__heading {
    flex: 0 0 58px;
    margin-top: 8px;
  }

  .filtering__filters {
    margin: -5px -4px;

    li {
      margin: 5px 4px;
    }
  }

  .filtering__filter {
    padding: 6px 20px;
  }
}