.breadcrumb {
  padding-top: 16px;
  font-weight: $font-weight--medium;

  & > .container {
    max-width: none;
  }

  &__nav {
    & > ol {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -8px; // for li margin-bottom

      li {
        padding-left: 0;

        &:before {
          content: none;
        }

        & > a {
          text-decoration: none;

          &:hover {
            text-decoration: $link__text-decoration;
          }
        }
      }
    }
  }

  &__icon {
    margin-left: 6px;
    margin-right: 8px;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .breadcrumb__icon {
    margin-left: 10px;
    margin-right: 12px;
  }
}