.related-content {
  @include margin-spacer(11, top);
  @include margin-spacer(14, bottom);
  
  &__entry-border {
    padding: 8px;
    margin-top: -8px;
    background-color: $light-grey;
    @include diagonal-line-pattern($mid-grey, $off-white);
  }

  &__entry {
    padding: 16px 16px 24px;
    background-color: $off-white;
  }

  &__entry-heading {
    margin-bottom: 12px;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .related-content {
    &__entry {
      padding: 24px 24px 32px;
    }
  }
}