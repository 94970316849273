@mixin button($btn__background-colour, $btn__text-colour) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn__background-colour;
  color: $btn__text-colour;
  outline: 0;
  border: $btn__border;
  border-radius: $btn__border-radius;
  padding: $btn__padding;
  font-weight: $btn__font-weight;
  text-decoration: none;
  transition: all $transition;
  font-size: $btn__font-size;
  line-height: $btn__line-height;

  &:not(:last-child) {
    margin-right: 0.5em;
  }

  &:hover {
    color: $btn-hover__text-colour;
    background-color: $btn-hover__background-colour;
  }

  &:active, &.active {
    color: $btn-active__text-colour;
    background-color: $btn-active__background-colour;
  }

  &:focus-visible {
    box-shadow: none; // override the link focus style
  }

  @media screen and (min-width: map-get($breakpoints, sm)) {
    padding: $btn__padding--desktop;
    font-size: $btn__font-size--desktop;
  }
}

button,
.button {
  @include button($btn__background-colour, $btn__text-colour);

  svg {
    margin-right: .8rem;
  }

  &.large {
    font-size: $base__font-size--mobile;
    line-height: $base__line-height--mobile;

    @media screen and (min-width: map-get($breakpoints, sm)) {
      font-size: $base__font-size--desktop;
      line-height: $base__line-height--desktop;
    }
  }
}