.pagination {
  &__heading {
    font-weight: $font-weight--medium;
    @include margin-spacer(3, top);
    @include margin-spacer(6, bottom);
  }

  &__numbers {
    @include list-reset();
    display: flex;
    flex-wrap: wrap;
    font-size: 1.8rem;
    line-height: 2.6rem;

    li {
      padding-left: 0;
      margin-left: 4px;
      margin-right: 4px;
      margin-bottom: 0;

      &:before {
        content: none;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px;
    background-color: $white;
    border: 1px solid;
    border-radius: 50%;
    text-decoration: none;

    &:hover {
      color: $link__colour;
      text-decoration: none;
      background-color: $light-grey;
    }

    &:active {
      color: $off-white;
      background-color: $link__colour;
    }

    &:focus-visible {
      box-shadow: none;
    }

    &__prev {
      svg {
        margin-left: -2px;
      }
    }

    &__next {
      svg {
        margin-right: -2px;
        margin-bottom: -1px;
      }
    }

    &__current {
      color: $white;
      background-color: $link__colour;

      &:hover, 
      &:active {
        color: $white;
        background-color: $link__colour;
      }
    }

    &.disabled {
      color: $dark-grey;
      background-color: transparent;
    }
  }

  &--desktop {
    display: none;
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .pagination {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
    }
  }
}