.container {
  width: 100%;
  max-width: $container__xl-width;
  padding-left: $container__gutter--sm;
  padding-right: $container__gutter--sm;
  margin-left: auto;
  margin-right: auto;

  // for matrix block inside matrix block i.e. text in accordion
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.container--lg {
  max-width: $container--lg-width;
}

.container--md {
  max-width: $container--md-width;
  margin-left: auto;
  margin-right: auto;

  &--left-align {
    margin-left: 0;
  }
}

.container--sm {
  max-width: $container--sm-width;
  margin-left: auto;
  margin-right: auto;

  &--left-align {
    margin-left: 0;
  }
}

//------//
// > xs //
//------//

@media screen and (min-width: map-get($breakpoints, xs)) {
  .container {
    padding-left: $container__gutter--md;
    padding-right: $container__gutter--md;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .container {
    padding-left: $container__gutter--lg;
    padding-right: $container__gutter--lg;
  }
}

//------//
// > 836 //
//------//

@media screen and (min-width: 836px) {
  .matrix, 
  .container__standfirst {
    .container--lg {
      margin-right: calc(50% - (#{$container--sm-width} / 2));
    }
  }
}

//------//
// > lg //
//------//

@media screen and (min-width: map-get($breakpoints, lg)) {
  .container {
    padding-left: $container__gutter--xl;
    padding-right: $container__gutter--xl;
  }
}
