//---------//
// Colours //
//---------//

// Brand colours
$white: #fff;
$off-white: #F8F5F0;
$black: #000;
$off-black: #3F3F3F;
$light-grey: #EAE4DC;
$mid-grey: #DBD3CC;
$dark-grey: #898886;
$green: #009F8C;
$dark-green:#005D5D;
$pink: #ED2FE0;
$dark-pink: #A3219A;
$blue: #4E53E5;
$red: #D50046;

// Colour assignment
$primary-colour: $off-black;

// Accessibility focus state colours
$accessibility__highlight: #FFE86D;
$accessibility__text: $off-black;
$accessibility__box-shadow: 0px -.125em 0px $accessibility__highlight, 0px 2px 0px $accessibility__highlight, 0px 6px 0px $off-black;

//--------//
// Layout //
//--------//

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1440px
);

// Container
$container__xl-width: 1248px;
$container--lg-width: 928px;
$container--md-width: 896px;
$container--sm-width: 736px;
$container__gutter--sm: 16px;
$container__gutter--md: 32px;
$container__gutter--lg: 48px;
$container__gutter--xl: 64px;

// Vertical spacers
$spacers--mobile: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 10px,
  5: 14px,
  6: 16px,
  7: 18px,
  8: 20px,
  9: 24px,
  10: 28px,
  11: 32px,
  12: 36px,
  13: 40px,
  14: 44px,
  15: 48px,
  16: 52px,
  17: 56px,
  18: 60px
);

$spacers--desktop: (
  1: 2px,
  2: 4px,
  3: 8px,
  4: 12px,
  5: 16px,
  6: 20px,
  7: 24px,
  8: 28px,
  9: 32px,
  10: 40px,
  11: 48px,
  12: 56px,
  13: 64px,
  14: 72px,
  15: 80px,
  16: 96px,
  17: 112px,
  18: 128px
);

//------------//
// Typography //
//------------//

// Weights
$font-weight--normal: 400;
$font-weight--medium: 500;
$font-weight--heavy: 600;
$font-weight--bold: 700;

// Base
$base__font-family: 'Inter', sans-serif;
$base__font-feature: 'ss01' on, 'cv05' on, 'cv10' on;
$base__font-colour: $off-black;
$base__font-size--mobile: 1.8rem;
$base__line-height--mobile: 2.6rem;
$base__font-size--desktop: 2rem;
$base__line-height--desktop: 3rem;

// Headings
$heading__font-family: 'TWK Everett', sans-serif;
$heading__font-feature: 'ss02' on;

// H1 - page-heading - h-xxl
$h1__colour: $base__font-colour;
$h1__font-weight: $font-weight--medium;
$h1__font-size--mobile: 3rem;
$h1__line-height--mobile: 3.6rem;
$h1__font-size--desktop: 6rem;
$h1__line-height--desktop: 7rem;

// H1 - display title (homepage)
$h1-disp__colour: $base__font-colour;
$h1-disp__font-weight: $font-weight--medium;
$h1-disp__font-size--mobile: 4rem;
$h1-disp__line-height--mobile: 4.6rem;
$h1-disp__font-size--desktop: 7.2rem;
$h1-disp__line-height--desktop: 8.2rem;

// H2 large - section-heading - h-xl
$h2-lg__colour: $base__font-colour;
$h2-lg__font-weight: $font-weight--medium;
$h2-lg__font-size--mobile: 2.6rem;
$h2-lg__line-height--mobile: 3.2rem;
$h2-lg__font-size--desktop: 4rem;
$h2-lg__line-height--desktop: 5rem;

// H2 - cross-heading - h-lg
$h2__colour: $base__font-colour;
$h2__font-weight: $font-weight--medium;
$h2__font-size--mobile: 2rem;
$h2__line-height--mobile: 2.6rem;
$h2__font-size--desktop: 2.6rem;
$h2__line-height--desktop: 3.6rem;

// cross-heading numbering 
$h2-num__colour: $base__font-colour;
$h2-num__font-weight: $font-weight--medium;
$h2-num__font-size--mobile: 5.4rem;
$h2-num__line-height--mobile: 3.8rem;
$h2-num__font-size--desktop: 7.4rem;
$h2-num__line-height--desktop: 5.6rem;

// H3 - p-heading - h-md
$h3__colour: $base__font-colour;
$h3__font-weight: $font-weight--medium;
$h3__font-size--mobile: 1.8rem;
$h3__line-height--mobile: 2.6rem;
$h3__font-size--desktop: 2.1rem;
$h3__line-height--desktop: 3rem;

// H3 - p-heading - h-md
$h3-disp__colour: $base__font-colour;
$h3-disp__font-weight: $font-weight--medium;
$h3-disp__font-size--mobile: 2rem;
$h3-disp__line-height--mobile: 2.6rem;
$h3-disp__font-size--desktop: 2.6rem;
$h3-disp__line-height--desktop: 3.6rem;

// H3 small - h-sm
$h3-sm__colour: $base__font-colour;
$h3-sm__font-weight: $font-weight--medium;
$h3-sm__font-size--mobile: 1.9rem;
$h3-sm__line-height--mobile: 2.6rem;
$h3-sm__font-size--desktop: 2.1rem;
$h3-sm__line-height--desktop: 3rem;

// H figure - figure-heading
$h-fig__colour: $base__font-colour;
$h-fig__font-weight: $font-weight--heavy;
$h-fig__font-size--mobile: 1.7rem;
$h-fig__line-height--mobile: 2rem;
$h-fig__font-size--desktop: 2rem;
$h-fig__line-height--desktop: 2.8rem;

// Stats - stat-heading - h-xxxl
$stat__colour: $base__font-colour;
$stat__font-weight: $font-weight--medium;
$stat__font-size--mobile: 6rem;
$stat__line-height--mobile: 7rem;
$stat__font-size--desktop: 12rem;
$stat__line-height--desktop: 12.8rem;

// paragraphs

// standfirst - pull-quote
$stf__colour: $base__font-colour;
$stf__font-weight: $font-weight--normal;
$stf__font-size--mobile: 2rem;
$stf__line-height--mobile: 2.8rem;
$stf__font-size--desktop: 2.6rem;
$stf__line-height--desktop: 3.6rem;

// title-caption
$lg__colour: $base__font-colour;
$lg__font-size--mobile: 2.2rem;
$lg__line-height--mobile: 2.8rem;
$lg__font-size--desktop: 3rem;
$lg__line-height--desktop: 4rem;

// caption - body-sm
$cap__colour: $base__font-colour;
$cap__font-weight: $font-weight--normal;
$cap__font-size--mobile: 1.6rem;
$cap__line-height--mobile: 2.4rem;
$cap__font-size--desktop: 1.7rem;
$cap__line-height--desktop: 2.4rem;

// body-xxs
$xxs__font-size--mobile: 1.2rem;
$xxs__line-height--mobile: 1.6rem;

// Links
$link__colour: $primary-colour;
$link__text-decoration: underline;
$link__text-decoration-thickness: 1px;
$link__text-underline-offset: 3px;
$link-hover__colour: $primary-colour;
$link-hover__text-decoration-thickness: 2px;
$link__text-decoration-thickness--lg: 2px;
$link__text-underline-offset--lg: 6px;
$link-hover__text-decoration-thickness--lg: 3px;

//---------//
// Buttons //
//---------//

$btn__background-colour: $white;
$btn__text-colour: $base__font-colour;
$btn__border: 1px solid $base__font-colour;
$btn__border-radius: 999px;
$btn__padding: 4px 16px;
$btn__padding--desktop: 7px 20px;
$btn__font-weight: $font-weight--normal;
$btn__font-size: 1.6rem;
$btn__font-size--desktop: 1.7rem;
$btn__line-height: 2.4rem;
$btn-hover__text-colour: $base__font-colour;
$btn-hover__background-colour: $light-grey;
$btn-active__text-colour: $white;
$btn-active__background-colour: $base__font-colour;

//-------//
// Forms //
//-------//

$field-input__padding: 13px 15px;
$field-input__border: 1px solid $black;
$field-input__border-radius: 0;

//-------------------------//
// Animation & interaction //
//-------------------------//

$transition__duration: 0.2s;
$transition__curve: ease;
$transition: $transition__duration $transition__curve;

$hover__effect: darken; // darken or lighten
$hover__effect-amount: 10%;