.main-logo {
  width: 187px;
  height: 70px;
  display: inline-block;
  background-image: url("/dist/images/logo/logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media screen and (min-width: map-get($breakpoints, md)) {
    width: 214px;
    height: 80px;
  }

  &--white {
    background-image: url("/dist/images/logo/logo_white.svg");
  }
}

@media screen and (min-width: map-get($breakpoints, sm)) {
  .main-logo {
    width: 211px;
    height: 80px;
  }
}