.accordion {
  @include margin-spacer(10, top);
  @include margin-spacer(10, bottom);
  border-top: 1px solid $dark-grey;
  border-bottom: 1px solid $dark-grey;

  &__icon {
    position: absolute;
    left: 0;
    top: 26px;
    transition: .25s transform;
  }

  &__title {
    color: inherit;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__trigger {
    position: relative;
    @include button-reset();
    @include padding-spacer(8, top);
    @include padding-spacer(8, bottom);
    padding-left: 26px;
    color: $link__colour;
    cursor: pointer;

    &:hover {
      .accordion__title {
        text-decoration: $link__text-decoration;
        text-decoration-thickness: $link__text-decoration-thickness;
        text-underline-offset: $link__text-underline-offset;
      }
    }

    &:active, &.active {
      color: inherit;
      background-color: transparent;
    }

    &:focus-visible {
      background-color: transparent;
      box-shadow: none;

      .accordion__title {
        background-color: $accessibility__highlight;
        color: $accessibility__text !important;
        box-shadow: $accessibility__box-shadow;
        text-decoration: none;
      }
    }
  }

  &.expanded {
    .accordion__icon {
      transform: rotate(180deg);
    }
  }

  &__body {
    display: none;
    padding-bottom: 32px;

    .container {
      padding: 0;
      margin: 0;
      max-width: none;
    }

    .matrix--text:last-child {
      p:last-child {
        @include margin-spacer(1, bottom);
      }
    }
  }
}

.accordion__explainer {
  &__icon {
    display: none;
  }
}

.matrix--q-and-a + .matrix--q-and-a,
.matrix--q-and-a + .matrix--accordion,
.matrix--accordion + .matrix--q-and-a, 
.matrix--accordion + .matrix--accordion {
  .accordion, 
  .accordion:first-child {
    margin-top: map-get($spacers--mobile, 10) * -1;
    border-top: none;
  }
}

//------//
// > sm //
//------//

@media screen and (min-width: map-get($breakpoints, sm)) {
  .accordion {
    &__icon {
      top: 36px;
    }

    &__trigger {
      padding-left: 34px;
    }
  }

  .accordion__explainer {
    .accordion__trigger {
      padding-right: 56px;
    }

    &__icon {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); // vertical centre
    }
  }

  .matrix--q-and-a + .matrix--q-and-a,
  .matrix--q-and-a + .matrix--accordion,
  .matrix--accordion + .matrix--q-and-a, 
  .matrix--accordion + .matrix--accordion {
    .accordion, 
    .accordion:first-child {
      margin-top: map-get($spacers--desktop, 10) * -1;
    }
  }
}
