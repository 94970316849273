.listing {
  @include padding-spacer(16, bottom);
}

.listing-section { // on homepage
  @include padding-spacer(16, top);
  @include padding-spacer(16, bottom);
}

.listing-section__heading {
  @include margin-spacer(14, bottom);
}

.listing--border-bottom {
  height: 8px;
  @include diagonal-line-pattern($mid-grey, $off-white);
}

.listing__entry--border-top {
  height: 8px;
  margin-top: -8px;
  @include diagonal-line-pattern($mid-grey, $off-white);
}

.listing__entry--content {
  @include padding-spacer(6, top);
  @include padding-spacer(10, bottom);
}

.listing__entry--title {
  @include margin-spacer(4, bottom);
}

.listing__entry--post-date {
  font-weight: $font-weight--medium;
  margin-bottom: 6px;
}

//------//
// > md //
//------//

@media screen and (min-width: map-get($breakpoints, md)) {
  .listing__entry--content {
    display: flex;
  }

  .listing__entry--post-date {
    flex: 0 0 192px;
    margin-top: 6px;
    margin-bottom: 0;
  }
}